<template>
  <div>
    <b-row align-h="between" class="mx-3">
      <h1 class="display-4 gateway-header">Gateways</h1>
    </b-row>

    <b-row class="my-3 m-0" no-gutters align-h="end" align-v="center">
      <b-col lg="6" class="d-flex my-1">
        <b-input-group size="sm">
          <b-form-input
            aria-label="search"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-column justify-content-between flex-grow-1">
      <b-table
        id="gateways-table"
        :items="gateways"
        :fields="tableFields"
        primaryKey="uuid"
        :show-empty="!loadingSites"
        :busy="loadingSites"
        emptyText="No Gateways available"
        :per-page="perPage"
        :current-page="currentPage"
        hover
        striped
        sort-icon-left
        stacked="sm"
        responsive
        @filtered="onFiltered"
        :filter="filter"
        :filter-function="filterMethod">

        <template v-slot:cell(name)="data">
          <div class="d-flex">
            {{ data.item.name }}
            <div v-if="data.item.status === 'OFFLINE'" class="pl-2">
              <div v-b-tooltip.hover.right title="The gateway is offline. Could not retrieve more information.">
                <font-awesome-icon icon="exclamation-circle" class="error-icon" />
              </div>
            </div>
            <div v-if="data.item.status === 'SERVER DISCONNECTED'" class="pl-2">
              <div v-b-tooltip.hover.right title="The server is disconnected">
                <font-awesome-icon icon="info-circle" style="color:grey;" />
              </div>
            </div>
          </div>
        </template>

        <template v-slot:cell(configure)="{ item }">
          <div class="d-inline-flex align-items-center justify-content-end w-100 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
            <msi-spinner class="no-select" :size="24" v-if="gatewaysOperation[item.uuid]" />
            <b-button aria-label="ping gateway" size="sm" class="ml-2" variant="primary" @click="() => handlePing(item)"
              :disabled="gatewaysOperation[item.uuid]">
              Ping
            </b-button>
            <b-button aria-label="refresh gateway" size="sm" class="ml-2" variant="primary" @click="() => handleRefresh(item)"
              :disabled="gatewaysOperation[item.uuid]">
              Refresh
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="w-100 d-flex justify-content-center">
        <msi-spinner class="no-select" :size="60" v-if="loadingSites"/>
      </div>

      <div class="d-flex justify-content-center mt-2 w-100">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :limit="$feature.mobile ? 5 : 8"
          aria-controls="gateways-table"
          class="m-0"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton, BTable, BPagination } from 'bootstrap-vue';
import moment from 'moment';

import MsiSpinner from '../MsiSpinner.vue';

export default {
  name: 'DaqLink',
  components: {
    MsiSpinner,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTable,
    BPagination
  },
  data() {
    return {
      gatewaysInfo: {},
      gatewaysOperation: {},
      initialized: false,
      tableFields: [
        { key: 'name', sortable: true },
        { key: 'code', sortable: true },
        { key: 'serialNumber', sortable: true },
        { key: 'version', sortable: true },
        { key: 'uptime' },
        { key: 'configure', label: '' }
      ],
      perPage: 12,
      currentPage: 1,
      totalRows: 1,
      filter: ''
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteGateways: get('gateways/getSiteGateways'),
    loadingSites: get('sites/loadingSites'),
    gatewayStatus: get('gateways/getGatewayStatus'),
    siteGateways() {
      if (!this.selectedSite) return [];
      return this.getSiteGateways(this.selectedSite.id);
    },
    gateways() {
      return this.siteGateways.map((gateway) => {
        const serialNumber = gateway.serialNumber ? gateway.serialNumber : '-';
        return { ...gateway, serialNumber, version: '-', uptime: '-', ...this.gatewaysInfo[gateway.uuid], status: this.gatewayStatus(gateway.code) };
      });
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filterMethod(gateway, filterString) {
      const parts = filterString.split(/ +/g).map(s => s.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'));
      const regex = RegExp(parts.join('.*'), 'i');
      if (gateway.name.match(regex)) return true;
      if (gateway.code.match(regex)) return true;
      if (gateway.version.match(regex)) return true;
      return false;
    },
    async handlePing(gateway) {
      try {
        this.$set(this.gatewaysOperation, gateway.uuid, true);
        const start = Date.now();
        const gatewayClient = await this.$broker.getGatewayClient(gateway);
        await gatewayClient.gateway.ping({});
        const end = Date.now();
        const repliedIn = (end - start) > 1000 ? `${Number.parseFloat(((end - start) / 1000).toFixed(1))} s` : `${end - start} ms`;
        this.$toastSuccess('Success', `${gateway.name} replied in ${repliedIn}.`);
      } catch (e) {
        this.$toastError('Error', `${gateway.name} did not reply.`);
      } finally {
        this.$set(this.gatewaysOperation, gateway.uuid, false);
      }
    },
    async handleRefresh(gateway) {
      try {
        this.$set(this.gatewaysOperation, gateway.uuid, true);
        const gatewayClient = await this.$broker.getGatewayClient(gateway);
        const info = await gatewayClient.gateway.info({});
        this.setGatewayInfo(gateway, info);
      } catch (e) {
        this.$toastError('Error', `${gateway.name} did not reply.`);
      } finally {
        this.$set(this.gatewaysOperation, gateway.uuid, false);
      }
    },
    setGatewayInfo(gateway, info) {
      info.uptime = moment.duration(info.uptime.seconds, 'seconds').humanize();
      this.$set(this.gatewaysInfo, gateway.uuid, info);
    }
  },
  watch: {
    selectedSite() {
      this.gatewaysInfo = {};
      this.gatewaysOperation = {};
      this.initialized = false;
    },
    siteGateways: {
      immediate: true,
      handler(gateways) {
        this.totalRows = gateways.length;
        if (gateways.length && !this.initialized) {
          this.initialized = true;
          gateways.forEach(async (gateway) => {
            try {
              const gatewayClient = await this.$broker.getGatewayClient(gateway);
              const info = await gatewayClient.gateway.info({});
              this.setGatewayInfo(gateway, info);
            } catch (e) {
              // ignore error
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.gateway-header {
  font-size: 1.8rem;
}

.error-icon {
  color: red;
  cursor: pointer;
}
</style>
