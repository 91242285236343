<template>
<b-container fluid class="gateway-config-container m-0 px-2 px-md-3 pt-2 pt-md-3 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column pb-2 pb-md-3 min-height-fit">
    <b-card no-body class="m-0 p-3 flex-grow-1 d-flex flex-column min-height-fit">
      <gateway-list />
    </b-card>
  </div>
</b-container>
</template>

<script>
import { BContainer, BCard } from 'bootstrap-vue';

import GatewayList from '../components/SiteSettings/GatewayList.vue';

export default {
  name: 'DaqLink',
  components: {
    GatewayList,
    BContainer,
    BCard
  }
};
</script>

<style scoped>
.gateway-config-container {
  height: calc(100vh - 105px)
}
</style>
